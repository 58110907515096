@import "../../styles/theme.scss";

.root {
  width: 100%;
}

.head {
  background-color: $cPrimaryDim;
}

.row {
  border-bottom: 1px solid $cGray3;
}

.column {
  padding: 10px;
}

.headColumn {
  color: $cPrimary;
  padding: 10px;
  font-weight: 600;
}

.noBorderBottom {
  border-bottom: none;
}

.bgWhite {
  background-color: $cWhite;
}

.rowClickable {
  cursor: pointer;

  &:hover {
    background-color: lighten($cPrimaryDim, 5%);
  }
}

.expandButton {
  background: none;
  border: none;
  color: $cBlue;
  width: 20px;
  margin-right: 5px;
}

.expandedColumnData {
  display: inline-block;
  width: 20px;
  margin-right: 5px;
}
